import axios from "axios";
import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import Loader from "./Loader";
import ModalRunningWhite from "./modal_running_white";
import { useParams } from "react-router-dom";
export default function Communication(props) {
  const [isLoading, setLoading] = useState(true);
  const [mails, setMails] = useState([]);
  const [zakazka, setZakazka] = useState([]);
  const [asd, setasd] = useState(false);

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const params = useParams();
  const [emailDeleted, setEmailDeleted] = useState(false);

  useEffect(() => {
    const url_query_string =
      "/emailsdisplay?fakturace=false&kampan_id=" + params.zakazka;
    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);

        setMails(res.data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );

    setZakazka(JSON.parse(localStorage.getItem("zakazka")));
  }, [emailDeleted]);

  const handleChangeInput = (event, ID) => {
    const arr = [...mails];

    var value_to_change;
    value_to_change = event.target.value;

    var colname_to_change;
    colname_to_change = "COMM_TYPE";

    const obj = arr.find((o) => o.ID === ID);
    obj[colname_to_change] = value_to_change;
    setMails(arr);
    axios.post("/emailsupdate?delete=0", obj).then((res) => {
      console.log(res);

      //setMails(res.data);
    });

    console.log(mails);
  };

  const handleChangeInput2 = (event, ID) => {
    const arr = [...mails];

    var value_to_change;
    value_to_change = event.target.value;

    var colname_to_change;
    colname_to_change = "EMAIL_TO";

    //treba otestovat ci uzivatel nemeni rovnakeho uzivatela, ak ano tak len updatnejme v novom array hodnotu

    const obj = arr.find((o) => o.ID === ID);
    obj[colname_to_change] = value_to_change;
    setMails(arr);
    axios.post("/emailsupdate?delete=0", obj).then((res) => {
      console.log(res);
      setEmailDeleted(!emailDeleted);
    });

    console.log(mails);
  };

  const deleteEmail = (ID) => {
    const url_query_string = "/emailsupdate?delete=1&id=" + ID;

    setIsRunning(true);
    setInfoMessage("Mazání emailu probíhá, prosím čekejte...");

    axios
      .post(url_query_string)
      .then((res) => {
        setEmailDeleted(!emailDeleted);
        setIsRunning(false);
      })
      .catch((err) => {
        setIsRunning(false);
      });
  };

  let urldown;
  let filename;

  const downloadEmail = async (ID, to, date, what) => {
    setIsRunning(true);
    setInfoMessage("Stahuji " + (what == "email" ? "e-mail" : "přílohu"));

    var url_query_string = "/emailsdownload?id=" + ID + "&what=" + what;

    const response = await axios({
      method: "GET",
      url: url_query_string,
    });
    console.log(response);
    urldown = what == "attachment" ? response.data[0] : response.data;
    filename = what == "attachment" ? response.data[1] : null;
    // setasd(true);

    //na presignedurl pouzivame fetch lebo ta nejde posielat TOKENY!!!!
    const uninterceptedAxiosInstance = axios.create();

    uninterceptedAxiosInstance(urldown, {
      method: "GET", //,
      responseType: "blob",
      // responseType: "arraybuffer",
    })
      .then((res) => {
        setIsRunning(false);
        what == "email"
          ? fileDownload(res.data, to + "_" + formatDate(date) + ".eml")
          : fileDownload(res.data, filename);
        setasd(res.data);
      })
      .catch((err) => {
        setIsRunning(false);
        console.log(err);
      });
  };
  /* if (asd != false) {
    return (
      <iframe
        src={`data:application/pdf;base64,${asd.toString("base64")}`}
        width="100%"
        height="500px"
      />
    );
  }*/

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <>
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <main class="content">
        {isLoading ? (
          <Loader message="" />
        ) : (
          <div class="container">
            <div class="content__in">
              <div class="content__main">
                <div class="table-container">
                  <div class="table">
                    <div class="table__head">
                      <div class="table__row">
                        <div class="table__col table__col--pin">
                          <div class="table-pin">
                            <div class="icon">
                              <svg></svg>
                            </div>
                          </div>
                        </div>

                        <div class="table__col table__col--pin">
                          <div class="table-pin">
                            <div class="icon">
                              <svg></svg>
                            </div>
                          </div>
                        </div>

                        <div class="table__col table__col--w200">Datum</div>

                        <div class="table__col table__col--w280">
                          Odesílatel
                        </div>

                        <div class="table__col table__col--w400">Předmět</div>
                        <div class="table__col table__col--w280">Kategorie</div>
                        <div class="table__col table__col--w280">Zakázka</div>
                        <div class="table__col table__col--w200"></div>
                      </div>
                    </div>

                    <div class="table__body">
                      {mails.map((mails, index) => (
                        <div class="table__row" key={index}>
                          <div class="table__col table__col--pin">
                            <div class="table-pin">
                              <div
                                class="icon"
                                onClick={() =>
                                  downloadEmail(
                                    mails.ID,
                                    mails.EMAIL_TO,
                                    mails.VALID_FROM,
                                    "attachment"
                                  )
                                }
                              >
                                <svg>
                                  <use
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    xlinkHref="/assets/img/sprite.svg#attachment"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div class="table__col table__col--pin">
                            <div class="table-pin">
                              <div
                                class="icon"
                                onClick={() =>
                                  downloadEmail(
                                    mails.ID,
                                    mails.EMAIL_TO,
                                    mails.VALID_FROM,
                                    "email"
                                  )
                                }
                              >
                                <svg>
                                  <use
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    xlinkHref="/assets/img/sprite.svg#email2"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div class="table__col table__col--w200">
                            {formatDate(mails.VALID_FROM)}
                          </div>
                          <div class="table__col table__col--w280">
                            {mails.SENDER_ADDRESS}
                          </div>

                          <div class="table__col table__col--w400">
                            {mails.EMAIL_SUBJECT}
                          </div>
                          <div class="table__col table__col--w280">
                            {props.user < 6 ? (
                              <select
                                className="form-select"
                                name="COMM_TYPE"
                                value={
                                  mails.COMM_TYPE === null
                                    ? "Přiřadit kategorii"
                                    : mails.COMM_TYPE
                                }
                                onChange={(event) =>
                                  handleChangeInput(event, mails.ID)
                                }
                              >
                                {mails.COMM_TYPE === null ? (
                                  <option>Přiřadit kategorii</option>
                                ) : (
                                  <></>
                                )}
                                <option value="1">Schválení mediaplánů</option>
                                <option value="2">Jiné</option>
                                <option value="3">Faktura</option>
                              </select>
                            ) : mails.COMM_TYPE === null ? (
                              "Přiřadit kategorii"
                            ) : (
                              mails.COMM_TYPE
                            )}
                          </div>
                          <div class="table__col table__col--w280">
                            {props.user < 6 ? (
                              <select
                                className="form-select"
                                name="ZAKAZKA"
                                value={mails.EMAIL_TO}
                                onChange={(event) =>
                                  handleChangeInput2(event, mails.ID)
                                }
                              >
                                {zakazka.map((zakazka) => (
                                  <option value={zakazka.KAMPAN_ID}>
                                    {zakazka.KAMPAN_ID}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              mails.EMAIL_TO
                            )}
                          </div>
                          <div class="table__col table__col--w200">
                            {props.user < 6 ? (
                              <a
                                onClick={() =>
                                  deleteEmail(
                                    mails.ID,
                                    mails.EMAIL_TO,
                                    mails.VALID_FROM
                                  )
                                }
                                class="btn btn--medium btn--bordered"
                              >
                                Odstranit
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}
