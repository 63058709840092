import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import Filter from "./Filter";
import { numberWithSpaces, formatDate } from "../helpers";
import ModalInfo from "./modal_info";
import ModalNewSK from "./Safekurzy-ModalNewSK";
import SafeKurzyMena from "./SafeKurzy-Mena";
import SafeKurzyClient from "./SafeKurzy-Client";
export default function SafeKurzy(props) {
  const [safeKurzy, setSafeKurzy] = useState([]);
  const [safeKurzyF, setSafeKurzyF] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [modalNewSK, setModalNewSK] = useState(false);
  const [sortDirection, setSortDirection] = useState(false);
  const [invoiceToEvidate, setInvoiceToEvidate] = useState([]);
  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [selectedMena, setSelectedMena] = useState("EUR");
  const [selectedClient, setSelectedClient] = useState(0);

  const params = useParams();

  useEffect(() => {
    const url_query_string = "/heliosinvoices?type=safe_kurzy";

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        console.log(res);
        setSafeKurzy(res.data);
        setSafeKurzyF(res.data);
        setLoading(false);
      } //,
      //(err) => {
      //  console.log(err);
      //}
    );
  }, []);

  if (isLoading) {
    return <Loader message="" />;
  }
  return (
    <>
      {modalNewSK && (
        <ModalNewSK
          setModalNewSK={setModalNewSK}
          setIsError={setIsError}
          setInfoBox={setInfoBox}
          setSafeKurzy={setSafeKurzy}
          setSafeKurzyF={setSafeKurzyF}
        />
      )}
      <ModalInfo
        infoBox={infoBox}
        setInfoBox={setInfoBox}
        setIsError={setIsError}
        isError={isError}
      />{" "}
      <SafeKurzyMena setSelectedMena={setSelectedMena} />
      <main class="content">
        <div class="container">
          <div class="content__in">
            <SafeKurzyClient
              safeKurzy={safeKurzy}
              setSelectedClient={setSelectedClient}
            />
            <div class="content__main">
              <div class="table-container">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--pin">
                        <div class="table-pin"></div>
                      </div>

                      <div class="table__col table__col--w160">Období</div>

                      <div class="table__col table__col--w160">
                        Nakupuji v cizí měne, fakturuji klientovi v CZK
                      </div>
                      <div class="table__col table__col--w160">
                        Nakupuji v CZK, fakturuji klientovi v cizí měne{" "}
                      </div>
                      <div class="table__col table__col--w100">
                        ČNB k 1. v měsíci odpoledne
                      </div>

                      <div class="table__col table__col--w100">Měna </div>
                      {selectedClient != 0 ? (
                        <div class="table__col table__col--w300">Klient </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div class="table__body">
                    {safeKurzyF
                      .filter((sk) => sk.MENA == selectedMena)
                      .filter(
                        (sk) =>
                          sk.CISLOORG ==
                          (selectedClient == 0 ? null : selectedClient)
                      )
                      .map((sk, index) => (
                        <div
                          class={"table__row"}
                          key={index}
                          // onClick={() => updateClient(sk.ID)}
                          style={{ cursor: "pointer" }}
                        >
                          <div class="table__col table__col--pin">
                            <div class="table-pin"></div>
                          </div>
                          <div class="table__col table__col--w160">
                            {formatDate(sk.OBDOBI)}
                          </div>

                          <div class="table__col table__col--w160">
                            {numberWithSpaces(sk.NAKUP_MENA)}
                          </div>
                          <div class="table__col table__col--w160">
                            {numberWithSpaces(sk.NAKUP_CZK)}
                          </div>
                          <div class="table__col table__col--w100">
                            {numberWithSpaces(sk.CNB_ER)}
                          </div>
                          <div class="table__col table__col--w100">
                            {numberWithSpaces(sk.MENA)}
                          </div>
                          {selectedClient != 0 ? (
                            <div class="table__col table__col--w300">
                              {numberWithSpaces(sk.CLIENT)}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                {props.user == 7 || props.user == 1 ? (
                  <div class="action-bar">
                    <div class="action-bar__in">
                      <a
                        onClick={() => {
                          setModalNewSK(true);
                        }}
                        className="btn btn--medium btn--primary"
                      >
                        Přidat kurzy
                      </a>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
