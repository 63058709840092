import React, { useState } from "react";
import axios from "axios";
import Filter from "./Filter";
import ModalRunning from "./modal_running";
import ModalInfo from "./modal_info";

export default function ModalList(props) {
  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const toggleModal = () => {
    props.setModalList(!props.modalList);
  };

  const ContinueEdit = () => {
    setInfoMessage("Čekejte prosím.");
    setIsRunning(true);

    axios.get("/users").then(
      (res) => {
        props.setUsers(res.data);
      },
      (err) => {
        setIsRunning(false);
        setInfoBox(
          "Došlo k chybě! Zkuste to znovu nebo kontaktuje administrátora."
        );
        setIsError(true);
        console.log(err);
      }
    );

    axios.get("/usersclients").then(
      (res) => {
        props.setUsersClients(res.data);
        props.setModalList(false);
        props.setModal(true);
        setIsRunning(false);
      },
      (err) => {
        setIsRunning(false);
        setInfoBox(
          "Došlo k chybě! Zkuste to znovu nebo kontaktuje administrátora."
        );
        setIsError(true);
        console.log(err);
      }
    );
  };

  return (
    <>
      {props.modalList && (
        <div class="modal-window modal-window--400px">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={infoMessage}
          />
          <ModalInfo
            isError={isError}
            setIsError={setIsError}
            infoBox={infoBox}
            setInfoBox={setInfoBox}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                Chystáte se hromadne editovat uživatelé v nasledujícich
                zakázkach:
              </div>
              <div class="modal-window__content">
                <div class="form">
                  <form class="form__in">
                    {props.zakazka
                      .filter((o) => o.SELECTED === 1)
                      .map((zakazka) => (
                        <div class="form__item">
                          <div class="form__value">
                            <label
                              for="id"
                              class="form-label form-label--inline"
                            >
                              {zakazka.KAMPAN_ID}
                            </label>
                          </div>
                        </div>
                      ))}
                  </form>
                </div>
              </div>

              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--primary"
                  onClick={() => ContinueEdit()}
                >
                  Pokračovat
                </a>
                <a
                  class="btn btn--medium btn--bordered"
                  onClick={() => props.setModalList(false)}
                >
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
