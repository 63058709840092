import React from "react";
import { useState } from "react";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  IsValidDate,
} from "../helpers";
import Validation from "./Validation";
import SelectClientModal from "./Modal_select_client";
import axios from "axios";
import ModalRunning from "./modal_running";
import ModalInfo from "./modal_info";
//import { json } from "react-router-dom";
export default function ModalNewSK(props) {
  const [validace, setValidace] = useState(0);
  const [validaceP, setValidaceP] = useState(false);
  const [validace_custom, setValidace_custom] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const heliosP = [
    { ID_HELIOS_P: 1, NAZEV: "Ostatní nemediální služby" },
    { ID_HELIOS_P: 2, NAZEV: "Mediální Inzerce" },
    { ID_HELIOS_P: 3, NAZEV: "Fee" },
    { ID_HELIOS_P: 5, NAZEV: "Obratové bonusy" },
  ];
  const polozka = {
    OBDOBI_YM: null,
    MENA: null,
    NAKUP_MENA: null,
    NAKUP_CZK: null,
    CNB_ER: null,
    CISLOORG: null,
    CLIENT: null,
  };
  const [polozky, setPolozky] = useState([polozka]);
  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const UpdatePolozky = (value, idx, col) => {
    const values = [...polozky];

    if (value == "") {
      values[idx][col] = null;
    } else {
      values[idx][col] = value;
    }
    setPolozky(values);
  };

  const InsertSK = () => {
    /* if (!IsValidDate(obdobi + "-01")) {
      setValidace("obdobi");
      return;
    }

    if (text == "") {
      setValidace("text");
      return;
    }

    for (let i = 0; i < polozky.length; i++) {
      var helios_p = polozky[i].ID_HELIOS_P;
      var id_stredisko = polozky[i].ID_STREDISKO;

      if (helios_p !== null && id_stredisko == null) {
        setValidace("stredisko");
        return;
      }
    }
    //pri rozpade poliziek na lubovolne nevyplnujeme kampan ID,
    // takze pole je prazdne treba ho vyplnit,
    //aj pre stredisko a aj pre samotnu fakturu
    if ((druhaZakazka == null) & (vyber == 6)) {
      polozky.map((obj) => {
        obj.ID_KAMPAN = props.vybranaZakazka;
      });
    }

    for (let i = 0; i < depositInvoices.length; i++) {
      if ("DRAWING" in depositInvoices[i])
        depositInvoices[i].DRAWING = parseFloat(
          depositInvoices[i].DRAWING.toString().replace(",", ".")
        );
    }
   
      polozky.map(function (x) {
      x.SKUPINA = x.SKUPINA == "Samostatně" ? 0 : x.SKUPINA;
      return x;
    });
    */

    for (let i = 0; i < polozky.length; i++) {
      if (!IsValidDate(polozky[i].OBDOBI_YM + "-01")) return;
    }

    for (let i = 0; i < polozky.length; i++) {
      if ("NAKUP_MENA" in polozky[i])
        if (
          (polozky[i].NAKUP_MENA !== null) &
          (polozky[i].NAKUP_MENA !== undefined)
        )
          polozky[i].NAKUP_MENA = parseFloat(
            polozky[i].NAKUP_MENA.toString().replace(",", ".")
          );
    }

    for (let i = 0; i < polozky.length; i++) {
      if ("NAKUP_CZK" in polozky[i])
        if (
          (polozky[i].NAKUP_CZK !== null) &
          (polozky[i].NAKUP_CZK !== undefined)
        )
          polozky[i].NAKUP_CZK = parseFloat(
            polozky[i].NAKUP_CZK.toString().replace(",", ".")
          );
    }

    for (let i = 0; i < polozky.length; i++) {
      if ("CNB_ER" in polozky[i])
        if ((polozky[i].CNB_ER !== null) & (polozky[i].CNB_ER !== undefined))
          polozky[i].CNB_ER = parseFloat(
            polozky[i].CNB_ER.toString().replace(",", ".")
          );
    }

    for (let i = 0; i < polozky.length; i++) {
      polozky[i]["OBDOBI"] = polozky[i].OBDOBI_YM + "-01";
    }

    var data = polozky;

    setIsRunning(true);
    setInfoMessage("Zakládam safe kurzy.");

    const url_query_string = "/heliosinvoices?type=safe_kurzy&what=create";

    axios
      .post(url_query_string, data)
      .then((res) => {
        console.log(res.data);
        props.setSafeKurzy(res.data);
        props.setSafeKurzyF(res.data);
        props.setModalNewSK(false);
        setIsRunning(false);
        props.setIsError(false);
        props.setInfoBox("Safe kurzy byli založeny.");
      })
      .catch((err) => {
        console.log(err);
        setInfoBox("Při zakladání safe kurzů došlo k chybě!");
        setIsRunning(false);
        setIsError(true);
      });
  };

  const toggleModal = () => {
    props.setModalNewSK(false);
  };

  return (
    <>
      <div class="modal-window modal-window--w1500">
        <ModalRunning
          isRunning={isRunning}
          setIsRunning={setIsRunning}
          message={infoMessage}
        />
        <ModalInfo
          isError={isError}
          setIsError={setIsError}
          infoBox={infoBox}
          setInfoBox={setInfoBox}
        />
        <div class="modal-window__wrap">
          <div class="modal-window__in">
            <div class="modal-window__title">Safe Kurzy</div>

            <div class="modal-window__content">
              <form class={"modal-form-6__in"}>
                {polozky.map((polozka, idx) => (
                  <>
                    <div class={"modal-form__item"}>
                      {idx == 0 ? (
                        <label for="id" class="form-label form-label--inline">
                          Období
                        </label>
                      ) : (
                        <></>
                      )}

                      <div class="form__value">
                        <input
                          class="form-input"
                          placeholder={idx == 0 ? "YYYY-MM" : ""}
                          value={polozka.OBDOBI_YM}
                          onChange={(e) => {
                            UpdatePolozky(e.target.value, idx, "OBDOBI_YM");
                          }}
                        />
                      </div>
                      {validaceP === true ? (
                        <Validation
                          validace={parseInt(validace)}
                          validace_custom={parseInt(validace_custom)}
                          message={"Vyplňte celý řádek!"}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div class={"modal-form__item"}>
                      {idx == 0 ? (
                        <label for={idx} class="form-label form-label--inline">
                          Měna
                        </label>
                      ) : (
                        <></>
                      )}

                      <div class="form__value">
                        <select
                          class="form-select"
                          value={polozka.MENA}
                          onChange={(e) =>
                            UpdatePolozky(e.target.value, idx, "MENA")
                          }
                          key={idx}
                        >
                          <option value="" selected disabled hidden></option>
                          <option value={"EUR"}>EUR</option>
                          <option value={"GBP"}>GBP</option>
                          <option value={"CHF"}>CHF</option>
                          <option value={"JPY"}>JPY</option>
                          <option value={"PLN"}>PLN</option>
                          <option value={"USD"}>USD</option>
                        </select>
                      </div>
                    </div>
                    <div class={"modal-form__item"}>
                      {idx == 0 ? (
                        <label for={idx} class="form-label form-label--inline">
                          Nákup cizí měna, fakturace CZK
                        </label>
                      ) : (
                        <></>
                      )}

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={polozka.NAKUP_MENA}
                          onChange={(e) => {
                            UpdatePolozky(e.target.value, idx, "NAKUP_MENA");
                          }}
                          onKeyDown={(e) => checkValidInput(e)}
                        />
                      </div>
                    </div>
                    <div class={"modal-form__item"}>
                      {idx == 0 ? (
                        <label for={idx} class="form-label form-label--inline">
                          Nákup CZK, fakturace cizí měna
                        </label>
                      ) : (
                        <></>
                      )}
                      <div class="form__value">
                        <input
                          class="form-input"
                          value={polozka.NAKUP_CZK}
                          onChange={(e) => {
                            UpdatePolozky(e.target.value, idx, "NAKUP_CZK");
                          }}
                          onKeyDown={(e) => checkValidInput(e)}
                        />
                      </div>
                    </div>
                    <div class={"modal-form__item"}>
                      {idx == 0 ? (
                        <label for={idx} class="form-label form-label--inline">
                          ČNB k 1. v měsíci odpoledne
                        </label>
                      ) : (
                        <></>
                      )}
                      <div class="form__value">
                        <input
                          class="form-input"
                          value={polozka.CNB_ER}
                          onChange={(e) => {
                            UpdatePolozky(e.target.value, idx, "CNB_ER");
                          }}
                          onKeyDown={(e) => checkValidInput(e)}
                        />
                      </div>
                    </div>
                    <div class={"modal-form__item"}>
                      {idx == 0 ? (
                        <label for={idx} class="form-label form-label--inline">
                          Klient
                        </label>
                      ) : (
                        <></>
                      )}
                      <div class="form__value">
                        {polozka.CLIENT != null ? (
                          <input class="form-input" value={polozka.CLIENT} />
                        ) : (
                          <SelectClientModal
                            UpdatePolozky={UpdatePolozky}
                            idx={idx}
                            rozpad={false}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ))}
                <div class={"modal-form__item"}> </div>
                <div class={"modal-form__item"}> </div>
                <div class={"modal-form__item"}> </div>
                <div class={"modal-form__item"}> </div>
                <div class={"modal-form__item"}>
                  <a
                    onClick={() => {
                      setPolozky([
                        ...polozky,
                        JSON.parse(JSON.stringify(polozka)), //json deep copy, lebo pri .push noveho objectu do array, ked menime hodnoty tak to meni hodnoty v celom array
                      ]);
                    }}
                    className="btn btn--small btn--transparent"
                  >
                    Přidat řádek
                  </a>
                </div>
                <div class={"modal-form__item"}>
                  <a
                    onClick={() => {
                      setPolozky(polozky.slice(0, -1));
                    }}
                    className="btn btn--small btn--transparent"
                  >
                    Odebrat řádek
                  </a>
                </div>
              </form>
              <br></br>
              <div class="modal-window__actions">
                <a class="btn btn--medium btn--primary" onClick={InsertSK}>
                  Uložit
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Zavřít
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
