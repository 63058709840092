import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Filter from "./Filter";
import FinancialInsightStandardTable from "./Order-FI_standard_table";
import FinancialInsightFeeOsTable from "./Order-FI_fee_os_table";
import { replaceComma } from "../helpers";
import Loader from "./Loader";
import ModalFiFakturace from "./Order-FI-Modal_fi_fakturace";

export default function ModalFiDobropis(props) {
  const [modalInvoiceToEvidate, setModalInvoiceToEvidate] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [mediaplanAgg, setMediaplanAgg] = useState([]);

  const [vybranyClient, setVybranyClient] = useState(false);
  const [financialInsightModal, setFinancialInsightModal] = useState([]);
  const [financialInsightModalF, setFinancialInsightModalF] = useState([]);

  const [sortDirection, setSortDirection] = useState(false);
  const [modalFakturace, setModalFakturace] = useState(false);
  const [validaceID, setvalidaceID] = useState(0);

  const sortColumn = (colName) => {
    const values = [...financialInsightModalF];

    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setFinancialInsightModalF(values);
  };

  useEffect(() => {
    const url_query_string =
      "/financialinsight?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp;

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);

        const values = [...res.data];
        if (props.uctarna === 2) {
          //dobropis bez naviazana na vydanu fakturu. Volane z FI
          const ids = props.invoiceToEvidate
            .filter((item) => item.SELECTED == 1)
            .map((item) => item.ID);
          var items = values.filter((o) => ids.includes(o.ID));
        } else {
          var items = values.filter(
            (o) =>
              o.ID_INVOICE_TO_HELIOS ==
              props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
          );
        }
        for (let i = 0; i < items.length; i++) {
          items[i]["MNNP_OPRAVA"] = null;
          items[i]["FEE_OPRAVA"] = null;
          items[i]["CNNN_OPRAVA"] = null;
        }

        setFinancialInsightModal(values);
        setFinancialInsightModalF(values);
        setLoading(false);
      },
      (err) => {}
    );
  }, []);

  const Dobropising = (col, id, val) => {
    const values = [...financialInsightModalF];
    var item = values.find((o) => o.ID == id);

    item[col] = val == "" ? null : val;

    if (
      (props.vybranyMediatyp != "Fee") &
      (props.vybranyMediatyp != "Other services")
    ) {
      item["CNNN_OPRAVA"] =
        (item["MNNP_OPRAVA"] == null
          ? 0
          : parseFloat(item["MNNP_OPRAVA"].toString().replace(",", "."))) +
        (item["FEE_OPRAVA"] == null
          ? 0
          : parseFloat(item["FEE_OPRAVA"].toString().replace(",", ".")));
    }
    setFinancialInsightModalF(values);
  };

  const Continue = () => {
    const values = [...financialInsightModal];
    var items = values.filter(
      (o) =>
        o.ID_INVOICE_TO_HELIOS ==
        props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
    );

    for (let i = 0; i < items.length; i++) {
      items[i]["SELECTED"] = 1;
      items[i]["FEE_OPRAVA"] =
        items[i]["FEE_OPRAVA"] == null
          ? 0
          : parseFloat(items[i]["FEE_OPRAVA"].toString().replace(",", "."));
      items[i]["MNNP_OPRAVA"] =
        items[i]["MNNP_OPRAVA"] == null
          ? 0
          : parseFloat(items[i]["MNNP_OPRAVA"].toString().replace(",", "."));
      items[i]["CNNN_OPRAVA"] =
        items[i]["CNNN_OPRAVA"] == null
          ? 0
          : parseFloat(items[i]["MNNP_OPRAVA"].toString().replace(",", "."));

      if (
        items[i]["MNNP_OPRAVA"] > 0 ||
        items[i]["FEE_OPRAVA"] > 0 ||
        items[i]["CNNN_OPRAVA"] > 0
      ) {
        return setvalidaceID(items[i]["ID"]);
      }
      setvalidaceID(0);
    }
    values.map((el) => {
      el.FEE_ORG = el.FEE;
      el.MNNP_ORG = el.MNNP;
      el.CNNN_ORG = el.CNNN;
      el.FEE = el.FEE_OPRAVA;
      el.MNNP = el.MNNP_OPRAVA;
      el.CNNN = el.CNNN_OPRAVA;
      delete el.FEE_OPRAVA;
      delete el.MNNP_OPRAVA;
      delete el.CNNN_OPRAVA;
    });

    setFinancialInsightModal(values);
    setModalFakturace(true);
  };

  const toggleModal = () => {
    const values = [...financialInsightModalF];
    for (let object of values) {
      object.SELECTED = 0;
    }
    setFinancialInsightModalF(values);

    setVybranyClient(false);
    props.setModalToEvidate(false);
  };

  return (
    <>
      {modalFakturace ? (
        <ModalFiFakturace
          setFinancialInsight={setFinancialInsightModal}
          setFinancialInsightF={setFinancialInsightModalF}
          financialInsight={financialInsightModal}
          modalFakturace={modalFakturace}
          setModalFakturace={setModalFakturace}
          setIsError={props.setIsError}
          setInfoBox={props.setInfoBox}
          //zakazka={ses}
          setUctarna={props.setUctarna}
          //setInvoiceCreated={setInvoiceCreated}
          //invoiceCreated={invoiceCreated}
          typFaktury={"dobropis"}
          invoiceToEvidate={props.invoiceToEvidate}
          setModalToEvidate={props.setModalToEvidate}
          vybranaZakazka={props.vybranaZakazka}
          vybranyMediatyp={props.vybranyMediatyp}
          user={props.user}
        />
      ) : (
        <div
          class={
            modalInvoiceToEvidate
              ? "modal-window"
              : "modal-window modal-window--flexible"
          }
        >
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                Vyberte položku k napárovaní
              </div>
              <div class="modal-window__content">
                <div class="table-container">
                  {isLoading ? (
                    <Loader message="" />
                  ) : props.vybranyMediatyp == "Other services" ||
                    props.vybranyMediatyp == "Fee" ||
                    props.vybranyMediatyp == "Internet" ? (
                    <FinancialInsightFeeOsTable
                      financialInsight={financialInsightModal}
                      financialInsightF={financialInsightModalF}
                      setFinancialInsightF={setFinancialInsightModalF}
                      sortColumn={sortColumn}
                      parentFI={"dobropis"}
                      Dobropising={Dobropising}
                      vybranyClient={vybranyClient}
                      uctarna={0}
                      invoiceToEvidate={
                        props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
                      }
                      validaceID={validaceID}
                      vybranyMediatyp={props.vybranyMediatyp}
                      user={props.user}
                    />
                  ) : (
                    <FinancialInsightStandardTable
                      financialInsightF={financialInsightModalF}
                      financialInsight={financialInsightModal}
                      setFinancialInsightF={setFinancialInsightModalF}
                      sortColumn={sortColumn}
                      parentFI={"dobropis"}
                      Dobropising={Dobropising}
                      vybranyClient={vybranyClient}
                      uctarna={props.uctarna}
                      invoiceToEvidate={
                        props.uctarna === 2
                          ? props.invoiceToEvidate
                          : props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
                      }
                      validaceID={validaceID}
                      user={props.user}
                    />
                  )}
                </div>
              </div>
              <div class="modal-window__actions">
                <a className="btn btn-medium btn--primary" onClick={Continue}>
                  Pokračovat
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
