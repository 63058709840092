import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";

//tailwind

axios.defaults.baseURL =
  "https://wc81duo900.execute-api.eu-central-1.amazonaws.com/test";
//"https://jqrigg3fbk.execute-api.eu-central-1.amazonaws.com/dev";

//axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
//  "token"
//)}`;
//axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = `Bearer ${localStorage.getItem("token")}`;
  config.headers.Authorization = token;

  return config;
});
/*
const header = document.getElementById("header");

// Get the initial left offset
const leftOffset = parseInt(window.getComputedStyle(header).left);

// Add scroll event listener to the window
window.addEventListener("scroll", function () {
  // Set the left position of the header
  header.style.left = `${window.scrollX + leftOffset}px`;
});
*/
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
