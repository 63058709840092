import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  numberWithSpaces,
  checkValidInput,
  CheckValidPaste,
  replaceDot,
} from "../helpers";

export default function FinancialInsightFeeOsTable(props) {
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  return (
    <div class="table">
      <div class="table__head">
        <div class="table__row table__row--bold">
          {props.vybranyMediatyp == "Other services" ||
          props.vybranyMediatyp == "Internet" ? (
            <div
              class={
                props.parentFI === "standard"
                  ? props.uctarna == 0
                    ? "table__col table__col--w140"
                    : "table__col table__col--w200 table__col--with-checkbox"
                  : "table__col table__col--w200 table__col--with-checkbox"
              }
            >
              Dodavatel
            </div>
          ) : (
            <></>
          )}
          <div
            class={
              props.vybranyMediatyp == "Fee" ||
              props.vybranyMediatyp == "Kickback"
                ? props.parentFI === "standard"
                  ? props.uctarna == 0
                    ? "table__col table__col--w140"
                    : "table__col table__col--w200 table__col--with-checkbox"
                  : "table__col table__col--w200 table__col--with-checkbox"
                : "table__col table__col--w140"
            }
          >
            Název služby
          </div>
          {props.vybranyMediatyp == "Fee" ||
          props.vybranyMediatyp == "Kickback" ? (
            <div class="table__col table__col--w140">Mediatyp</div>
          ) : (
            <></>
          )}
          {props.vybranyMediatyp == "Other services" ||
          props.vybranyMediatyp == "Internet" ? (
            <>
              <div class="table__col table__col--w120">Číslo faktury</div>
            </>
          ) : (
            <></>
          )}

          <div class="table__col table__col--w140">
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("DATUM_UVEREJNENI")}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => props.sortColumn("DATUM_UVEREJNENI_M")}
                >
                  Fakturované období
                </span>
              </OverlayTrigger>
            ) : (
              "Fakturované období"
            )}
            <Filter
              filter={filter}
              setFilter={setFilter}
              filterVal={filterVal}
              setFilterVal={setFilterVal}
              table={props.financialInsight}
              filterInput={filterInput}
              setFilterInput={setFilterInput}
              setFilteredTable={props.setFinancialInsightF}
              colName={"DATUM_UVEREJNENI_M"}
            />
          </div>
          {props.vybranyMediatyp == "Other services" ||
          props.vybranyMediatyp == "Internet" ? (
            <div class="table__col table__col--w140">
              {props.parentFI === "standard" ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={props.renderTooltip("MNN")}
                >
                  <span>
                    {props.vybranyMediatyp == "Internet"
                      ? "Media Net Net Nákup"
                      : "Nákup - částka (bez DPH)"}
                  </span>
                </OverlayTrigger>
              ) : props.vybranyMediatyp == "Internet" ? (
                "Media Net Net Nákup"
              ) : (
                "Nákup - částka (bez DPH)"
              )}
            </div>
          ) : (
            <></>
          )}
          <div class="table__col table__col--w140">
            {props.vybranyMediatyp == "Other services"
              ? "Prodej - částka (bez DPH)"
              : ""}
            {props.vybranyMediatyp == "Internet" ? "Media Net Net Prodej" : ""}
            {props.vybranyMediatyp == "Fee" ||
            props.vybranyMediatyp == "Kickback"
              ? "Cost to Client"
              : ""}
          </div>
          {props.parentFI === "dobropis" ? (
            <div class="table__col table__col--w140">
              {props.vybranyMediatyp == "Other services"
                ? "Prodej - částka (bez DPH) - dobropis"
                : ""}
              {props.vybranyMediatyp == "Internet"
                ? "Media Net Net Prodej - dobropis"
                : ""}
              {props.vybranyMediatyp == "Fee" ||
              props.vybranyMediatyp == "Kickback"
                ? "Cost to Client - dobropis"
                : ""}
            </div>
          ) : (
            <></>
          )}
          {props.vybranyMediatyp == "Internet" ? (
            <>
              <div class="table__col table__col--w140">Fee</div>
              {props.parentFI === "dobropis" ? (
                <div class="table__col table__col--w140">Fee - dobropis</div>
              ) : (
                <></>
              )}
              <div class="table__col table__col--w140">Cost to Client</div>
            </>
          ) : (
            <></>
          )}
          {props.vybranyMediatyp == "Other services" ||
          props.vybranyMediatyp == "Internet" ? (
            <>
              <div class="table__col table__col--w140">Prodej - Nákup</div>
              <div class="table__col table__col--w160">Vysvětlení delty</div>
            </>
          ) : (
            <></>
          )}
          <div class="table__col table__col--flexible">Komentář</div>
        </div>
      </div>

      <div class="table__body">
        {props.financialInsightF
          .filter(
            props.parentFI === "dobropis"
              ? (o) => o.ID_INVOICE_TO_HELIOS == props.invoiceToEvidate
              : (o) => o.ID_INVOICE_TO_HELIOS == o.ID_INVOICE_TO_HELIOS
          )
          .map((financialInsight, index) => (
            <div
              class={
                financialInsight.ID_INVOICE_TO_HELIOS != null
                  ? "table__row table__row--highlight"
                  : "table__row"
              }
              key={financialInsight.ID}
              {...((props.parentFI === "standard") & (props.uctarna === 0)
                ? {
                    onClick: () => props.updateInvoice(financialInsight.ID),
                    style: { cursor: "pointer" },
                  }
                : {})}
            >
              {(props.parentFI !== "fakturace") &
              (props.parentFI !== "invoices") ? (
                props.uctarna == 0 ? (
                  <div class="table__col table__col--w140">
                    {props.vybranyMediatyp == "Other services" ||
                    props.vybranyMediatyp == "Internet"
                      ? (financialInsight.PARTNER == null
                          ? ""
                          : financialInsight.PARTNER) +
                        (financialInsight.PARTNER_KAT != null
                          ? " - " + financialInsight.PARTNER_KAT
                          : "")
                      : ""}
                    {props.vybranyMediatyp == "Fee" ? "FEE" : ""}
                    {props.vybranyMediatyp == "Kickback" ? "Kickback" : ""}
                  </div>
                ) : (
                  <div class="table__col table__col--inner-cols table__col--w200">
                    <div class="table__inner-columns">
                      <div class="table__inner-col">
                        <div class="form-checkbox form-checkbox--no-label">
                          {props.uctarna == 2 ? ( // podmienka pre delete riadku, nemoze byt odoslany k fakturacii a zaroven mat priradenu fakturu
                            financialInsight.ID_INVOICE_TO_HELIOS == null &&
                            financialInsight.INVOICE_ALIAS == null ? (
                              <>
                                <input
                                  type="checkbox"
                                  defaultChecked={financialInsight.SELECTED}
                                  onChange={(event) => {
                                    props.SelectMediaplan(event, index);
                                  }}
                                  id={financialInsight.ID}
                                />
                                <label htmlFor={financialInsight.ID}></label>{" "}
                              </>
                            ) : (
                              <></>
                            )
                          ) : financialInsight.ID_INVOICE_TO_HELIOS == null ? (
                            <>
                              <input
                                type="checkbox"
                                defaultChecked={financialInsight.SELECTED}
                                onChange={(event) => {
                                  props.SelectMediaplan(event, index);
                                }}
                                id={financialInsight.ID}
                              />
                              <label htmlFor={financialInsight.ID}></label>{" "}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div class="table__inner-col">
                        {props.vybranyMediatyp == "Other services" ||
                        props.vybranyMediatyp == "Internet"
                          ? (financialInsight.PARTNER == null
                              ? ""
                              : financialInsight.PARTNER) +
                            (financialInsight.PARTNER_KAT != null
                              ? " - " + financialInsight.PARTNER_KAT
                              : "")
                          : ""}
                        {props.vybranyMediatyp == "Fee" ? "FEE" : ""}
                        {props.vybranyMediatyp == "Kickback" ? "Kickback" : ""}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div class="table__col table__col--inner-cols table__col--w200">
                  <div class="table__inner-columns">
                    <div class="table__inner-col">
                      <div class="form-checkbox form-checkbox--no-label">
                        {props.parentFI == "invoices" ? (
                          props.uctarna == 1 ? ( //ked priradujeme fakturu k viacerym riadkom FP, tak mozme vybrat len jednu fakturu
                            props.vybranyClient ? ( //vybranyclient funguje len u recieved invoices FI, lebo je to obmedzene na jeden riadok
                              financialInsight.SELECTED === 1 ? ( // toto tu je kvoli tomu zeby som vedel odselektnut selektnuty
                                <>
                                  <input
                                    type="checkbox"
                                    // defaultChecked={mediaplanik.SELECTED}
                                    onChange={(event) => {
                                      props.SelectMediaplan(event, index);
                                    }}
                                    id={index}
                                  />
                                  <label for={index}> </label>
                                </>
                              ) : (
                                <></>
                              )
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  // defaultChecked={mediaplanik.SELECTED}
                                  onChange={(event) => {
                                    props.SelectMediaplan(event, index);
                                  }}
                                  id={index}
                                />
                                <label for={index}> </label>
                              </>
                            )
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                // defaultChecked={mediaplanik.SELECTED}
                                onChange={(event) => {
                                  props.SelectMediaplan(event, index);
                                }}
                                id={index}
                              />
                              <label for={index}> </label>
                            </>
                          )
                        ) : financialInsight.ID_INVOICE_TO_HELIOS == null ? (
                          <>
                            <input
                              type="checkbox"
                              // defaultChecked={mediaplanik.SELECTED}
                              onChange={(event) => {
                                props.SelectMediaplan(event, index);
                              }}
                              id={index}
                            />
                            <label for={index}> </label>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div class="table__inner-col">
                      {props.vybranyMediatyp == "Other services" ||
                      props.vybranyMediatyp == "Internet"
                        ? (financialInsight.PARTNER == null
                            ? ""
                            : financialInsight.PARTNER) +
                          (financialInsight.PARTNER_KAT != null
                            ? " - " + financialInsight.PARTNER_KAT
                            : "")
                        : ""}
                    </div>
                  </div>
                </div>
              )}

              {props.vybranyMediatyp == "Other services" ||
              props.vybranyMediatyp == "Internet" ? (
                <div class="table__col table__col--w140">
                  {financialInsight.NAZEV_SLUZBY}
                </div>
              ) : (
                <></>
              )}
              {props.vybranyMediatyp == "Fee" ||
              props.vybranyMediatyp == "Kickback" ? (
                <div class="table__col table__col--w140">
                  {financialInsight.MEDIATYP}
                </div>
              ) : (
                <></>
              )}
              {props.vybranyMediatyp == "Other services" ||
              props.vybranyMediatyp == "Internet" ? (
                <>
                  <div class="table__col table__col--w120">
                    {financialInsight.INVOICE_ALIAS}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div class="table__col table__col--w140">
                {financialInsight.DATUM_UVEREJNENI_M}
              </div>
              {props.vybranyMediatyp == "Other services" ||
              props.vybranyMediatyp == "Internet" ? (
                <div class="table__col table__col--w140">
                  {numberWithSpaces(financialInsight.MNNN)}
                </div>
              ) : (
                <></>
              )}
              <div class="table__col table__col--w140">
                {props.vybranyMediatyp == "Internet"
                  ? numberWithSpaces(financialInsight.MNNP)
                  : numberWithSpaces(financialInsight.CNNN)}
              </div>
              {props.parentFI === "dobropis" ? (
                <div class="table__col table__col--w140">
                  <input
                    class={
                      props.validaceID == financialInsight.ID
                        ? "form-input form-input--invalid"
                        : "form-input"
                    }
                    name="OVD_GRPS_PERCENT"
                    onKeyDown={(e) => checkValidInput(e)}
                    onPaste={(e) => CheckValidPaste(e)}
                    value={replaceDot(
                      props.vybranyMediatyp == "Internet"
                        ? financialInsight.MNNP_OPRAVA
                        : financialInsight.CNNN_OPRAVA
                    )}
                    onChange={(e) =>
                      props.Dobropising(
                        props.vybranyMediatyp == "Internet"
                          ? "MNNP_OPRAVA"
                          : "CNNN_OPRAVA",
                        financialInsight.ID,
                        e.target.value
                      )
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              {props.vybranyMediatyp == "Internet" ? (
                <>
                  <div class="table__col table__col--w140">
                    {numberWithSpaces(financialInsight.FEE)}
                  </div>
                  {props.parentFI === "dobropis" ? (
                    <div class="table__col table__col--w140">
                      <input
                        class={
                          props.validaceID == financialInsight.ID
                            ? "form-input form-input--invalid"
                            : "form-input"
                        }
                        name="OVD_GRPS_PERCENT"
                        onKeyDown={(e) => checkValidInput(e)}
                        onPaste={(e) => CheckValidPaste(e)}
                        value={replaceDot(financialInsight.FEE_OPRAVA)}
                        onChange={(e) =>
                          props.Dobropising(
                            "FEE_OPRAVA",
                            financialInsight.ID,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div class="table__col table__col--w140">
                    {numberWithSpaces(financialInsight.CNNN)}
                  </div>
                </>
              ) : (
                <></>
              )}
              {props.vybranyMediatyp == "Other services" ||
              props.vybranyMediatyp == "Internet" ? (
                <>
                  <div class="table__col table__col--w140">
                    {numberWithSpaces(financialInsight.MNNP_MNNN)}
                  </div>
                  <div class="table__col table__col--w160">
                    {financialInsight.MNNP_MNNN_FI_ID_LABEL}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div class="table__col table__col--flexible">
                {financialInsight.NOTE_1}
              </div>
            </div>
          ))}
        {props.parentFI === "standard" ? (
          <div class="table__body">
            <div class="table__row" key="sumacny">
              <div
                class={
                  props.uctarna == 0
                    ? "table__col table__col--w140 table__col--hidden"
                    : "table__col table__col--w200 table__col--hidden"
                }
              ></div>

              {props.vybranyMediatyp == "Other services" ? (
                <>
                  <div class="table__col table__col--w120 table__col--hidden"></div>

                  <div class="table__col table__col--w140 table__col--hidden"></div>
                </>
              ) : props.vybranyMediatyp == "Internet" ? (
                <>
                  <div class="table__col table__col--w120 table__col--hidden"></div>
                  <div class="table__col table__col--w140 table__col--hidden"></div>
                </>
              ) : (
                <></>
              )}
              <div class="table__col table__col--w140 table__col--hidden"></div>

              {props.vybranyMediatyp == "Other services" ||
              props.vybranyMediatyp == "Internet" ? (
                <>
                  <div class="table__col table__col--w140 table__col--highlight-green">
                    {numberWithSpaces(
                      props.financialInsightF.reduce(
                        (n, { MNNN }) => n + MNNN,
                        0
                      )
                    )}
                  </div>
                </>
              ) : (
                <div class="table__col table__col--w140 table__col--hidden"></div>
              )}

              <div class="table__col table__col--w140 table__col--highlight-green">
                {props.vybranyMediatyp == "Internet"
                  ? numberWithSpaces(
                      props.financialInsightF.reduce(
                        (n, { MNNP }) => n + MNNP,
                        0
                      )
                    )
                  : numberWithSpaces(
                      props.financialInsightF.reduce(
                        (n, { CNNN }) => n + CNNN,
                        0
                      )
                    )}
              </div>
              {props.vybranyMediatyp == "Internet" ? (
                <>
                  <div class="table__col table__col--w140 table__col--highlight-green">
                    {numberWithSpaces(
                      props.financialInsightF.reduce((n, { FEE }) => n + FEE, 0)
                    )}
                  </div>
                  <div class="table__col table__col--w140 table__col--highlight-green">
                    {numberWithSpaces(
                      props.financialInsightF.reduce(
                        (n, { CNNN }) => n + CNNN,
                        0
                      )
                    )}
                  </div>
                  <div class="table__col table__col--w140 table__col--highlight-green">
                    {numberWithSpaces(
                      props.financialInsightF.reduce(
                        (n, { MNNP_MNNN }) => n + MNNP_MNNN,
                        0
                      )
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div class="table__col table__col--flexible table__col--hidden"></div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
