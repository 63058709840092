import React, { useState } from "react";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  CheckValidPaste,
} from "../helpers";
import SelectClientModalHelios from "./Modal_select_client_helios";
export default function FormUpEvFeeOs(props) {
  return (
    <form class="modal-form__in">
      {props.vybranyMediatyp == "Other services" ||
      props.vybranyMediatyp == "Internet" ? (
        <>
          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Vydavatel
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={props.myArr[0]["PARTNER"]}
                readOnly
                // onChange={(e) => props.UpdateVal(e.target.value)}
              />
              {props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null ? (
                <div class="form__actions">
                  <SelectClientModalHelios
                    UpdateVal={props.UpdateVal}
                    from="update"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Název služby
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={props.myArr[0]["NAZEV_SLUZBY"]}
                {...(props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null
                  ? {
                      onChange: (e) =>
                        props.UpdateVal(e.target.value, "NAZEV_SLUZBY"),
                    }
                  : { readOnly: true })}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {props.vybranyMediatyp == "Fee" || props.vybranyMediatyp == "Kickback" ? (
        <div class="modal-form__item">
          <label for="id" class="form-label form-label--inline">
            Mediatyp
          </label>

          <div class="modal-form__value">
            <input
              class="form-input"
              value={props.myArr[0]["MEDIATYP"]}
              readOnly
              // onChange={(e) => props.UpdateVal(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div class="modal-form__item">
        <label for="id" class="form-label form-label--inline">
          Fakturované období
        </label>

        <div class="modal-form__value">
          <input
            class="form-input"
            value={props.myArr[0]["DATUM_UVEREJNENI_M"]}
            readOnly
            //   onChange={(e) => props.UpdateVal(e.target.value)}
          />
        </div>
      </div>
      {props.vybranyMediatyp == "Other services" ||
      props.vybranyMediatyp == "Internet" ? (
        <>
          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Číslo faktury
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={props.myArr[0]["INVOICE_ALIAS"]}
                readOnly
                // onChange={(e) => props.UpdateVal(e.target.value)}
              />
            </div>
          </div>

          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              {props.vybranyMediatyp == "Other services"
                ? "Nákup - částka (bez DPH)"
                : "Media Net Net Nákup"}
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={numberWithSpaces(props.myArr[0]["MNNN"])}
                readOnly
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {props.vybranyMediatyp == "Other services" ||
      props.vybranyMediatyp == "Fee" ||
      props.vybranyMediatyp == "Kickback" ? (
        <div class="modal-form__item">
          <label for="id" class="form-label form-label--inline">
            {props.vybranyMediatyp == "Other services"
              ? "Prodej - částka (bez DPH)"
              : "Cost to Client"}
          </label>

          <div class="modal-form__value">
            <input
              class="form-input"
              value={props.myArr[0]["CNNN"]}
              {...(props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null
                ? {
                    onChange: (e) => props.UpdateVal(e.target.value, "CNNN"),
                    onKeyDown: (e) => checkValidInput(e),
                    onPaste: (e) => CheckValidPaste(e),
                  }
                : { readOnly: true })}
            />
          </div>
        </div>
      ) : (
        <>
          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Media Net Net Prodej
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={props.myArr[0]["MNNP"]}
                placeholder="150000.. - Povinné"
                {...(props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null
                  ? {
                      onChange: (e) => props.UpdateVal(e.target.value, "MNNP"),
                      onKeyDown: (e) => checkValidInput(e),
                      onPaste: (e) => CheckValidPaste(e),
                    }
                  : { readOnly: true })}
              />
            </div>
            {props.validace == "MNNP" ? (
              <div class="validation">
                <div class="validation__item">Povinné pole!</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Fee
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={props.myArr[0]["FEE"]}
                placeholder="150000.. - Povinné"
                {...(props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null
                  ? {
                      onChange: (e) => props.UpdateVal(e.target.value, "FEE"),
                      onKeyDown: (e) => checkValidInput(e),
                      onPaste: (e) => CheckValidPaste(e),
                    }
                  : { readOnly: true })}
              />
            </div>
            {props.validace == "FEE" ? (
              <div class="validation">
                <div class="validation__item">Povinné pole!</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Cost to Client
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={numberWithSpaces(
                  replaceComma(
                    props.myArr[0]["MNNP"] == "" ? 0 : props.myArr[0]["MNNP"]
                  ) +
                    replaceComma(
                      props.myArr[0]["FEE"] == "" ? 0 : props.myArr[0]["FEE"]
                    )
                )}
                readOnly
              />
            </div>
          </div>
        </>
      )}

      {props.vybranyMediatyp == "Other services" ||
      props.vybranyMediatyp == "Internet" ? (
        <>
          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Prodej - nákup
            </label>

            <div class="modal-form__value">
              <input
                class="form-input"
                value={numberWithSpaces(
                  (props.vybranyMediatyp == "Other services"
                    ? replaceComma(props.myArr[0]["CNNN"])
                    : replaceComma(props.myArr[0]["MNNP"])) -
                    props.myArr[0]["MNNN"]
                )}
                readOnly
              />
            </div>
          </div>
          <div class="modal-form__item">
            <label for="id" class="form-label form-label--inline">
              Vysvětlení delty
            </label>

            <div class="modal-form__value">
              {props.user != 4 ? (
                <select
                  class="form-select"
                  value={props.myArr[0]["MNNP_MNNN_FI_ID"]}
                  onChange={(e) =>
                    props.UpdateVal(e.target.value, "MNNP_MNNN_FI_ID")
                  }
                >
                  <option value="" selected disabled hidden></option>
                  <option value={1}>Kurzový rozdíl</option>
                  <option value={2}>Mediální delta</option>
                  <option value={3}>Nemediální delta</option>
                  <option value={4}>Fakturace v jiném měsíci</option>
                  <option value={5}>Kombinace více možnosti</option>
                  <option value={6}>Ostatní</option>
                </select>
              ) : (
                <input
                  class="form-input"
                  value={props.myArr[0]["MNNP_MNNN_FI_ID_LABEL"]}
                  readOnly
                  //   onChange={(e) => props.UpdateVal(e.target.value, "MNR")}
                />
              )}
              {props.validace === 1 ? (
                <div class="validation">
                  <div class="validation__item">Vyberte vysvětlení!</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div class="modal-form__item modal-form__item--full"></div>
      <div class="modal-form__item modal-form__item--full">
        <label for="id" class="form-label form-label--inline">
          Komentář
        </label>

        <div class="modal-form__value">
          <textarea
            placeholder="Max. 500 znaků.."
            type="text"
            class="form-textarea"
            value={props.myArr[0]["NOTE_1"]}
            onChange={(e) => props.UpdateVal(e.target.value, "NOTE_1")}
          />
        </div>
      </div>
    </form>
  );
}
