import React, { useState } from "react";
import axios from "axios";
import Filter from "./Filter";
import ModalRunning from "./modal_running";
import ModalInfo from "./modal_info";

export default function ModalMassEdit(props) {
  const [validace, setValidace] = useState(0);

  const [isCL, setIsCL] = useState(false);
  const [isCSC, setIsCSC] = useState(false);
  const [iszCSC, setIszCSC] = useState(false);
  const [isNAKUP, setIsNAKUP] = useState(false);
  const [iszNAKUP, setIszNAKUP] = useState(false);

  const [userSelected, setUserSelected] = useState(false);
  const [roleSelected, setRoleSelected] = useState(false);

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const toggleModal = () => {
    props.setModal(!props.modal);
  };

  const SelectUser = (event, index) => {
    setUserSelected(!userSelected);

    const values = [...props.users];
    var item = values.find((o) => o.ID == index);
    item["SELECTED"] = event.target.checked === true ? 1 : 0;

    props.setUsers(values);
  };

  const UnselectUser = () => {
    const values = [...props.users];
    for (let object of values) {
      object.SELECTED = 0;
    }
    props.setUsers(values);
    setUserSelected(false);
  };
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const HromadnyEdit = async () => {
    const data = props.zakazka.filter((o) => o.SELECTED == 1);
    var picked_user = props.users.filter((o) => o.SELECTED == 1);

    var validace_role;
    var validace_u;
    validace_role = isCL || isCSC || iszCSC || isNAKUP || iszNAKUP ? 0 : 1;
    validace_u =
      validace_role == 0 ? (iszCSC || iszNAKUP ? 0 : userSelected ? 0 : 1) : 2;

    setValidace(validace_u);

    if (validace_u == 0) {
      picked_user =
        iszCSC || iszNAKUP
          ? userSelected
            ? picked_user[0]["ID"]
            : null
          : picked_user[0]["ID"];
      setInfoMessage("Editují zakázku");
      setIsRunning(true);

      const url_query_string =
        "/editorder?user=" +
        picked_user +
        "&cl=" +
        isCL +
        "&csc=" +
        isCSC +
        "&cscz=" +
        iszCSC +
        "&nakup=" +
        isNAKUP +
        "&nakupz=" +
        iszNAKUP +
        "&mass=true";
      axios
        .post(url_query_string, data)
        .then((res) => {
          console.log(res.data);
          setIsRunning(false);
          setInfoBox("Zakázky byli editovány!");
          setIsError(false);
          props.setRolesUpdated(!props.rolesUpdated);
        })
        .catch((err) => {
          setIsRunning(false);
          setInfoBox("Zakázky se nezdařilo editovat.");
          setIsError(true);
        });
      await delay(4000);
      props.setModal(false);
      setInfoBox("");
    }
  };

  return (
    <>
      <div class="modal-window modal-window--680px">
        <ModalRunning
          isRunning={isRunning}
          setIsRunning={setIsRunning}
          message={infoMessage}
        />
        <ModalInfo
          isError={isError}
          setIsError={setIsError}
          infoBox={infoBox}
          setInfoBox={setInfoBox}
        />
        <div class="modal-window__wrap">
          <div class="modal-window__close" onClick={toggleModal}>
            <div class="icon">
              <svg>
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="/assets/img/sprite.svg#cross"
                />
              </svg>
            </div>
          </div>
          <div class="modal-window__in">
            <div class="modal-window__title">Vyberte roli a užívatele</div>
            <div class="modal-window__content">
              <div class="form">
                <div class="form__columns">
                  <div class="form__col">
                    <form class="form__in">
                      {props.user <= 2 ? (
                        <div class="form__item">
                          <div class="form__value">
                            <div class="form-checkbox">
                              <input
                                type="checkbox"
                                checked={isCL}
                                {...(roleSelected
                                  ? isCL
                                    ? {
                                        onChange: () => {
                                          setIsCL(!isCL);
                                          UnselectUser();
                                          setRoleSelected(!roleSelected);
                                        },
                                      }
                                    : { readOnly: true }
                                  : {
                                      onChange: () => {
                                        setIsCL(!isCL);
                                        UnselectUser();
                                        setRoleSelected(!roleSelected);
                                      },
                                    })}
                                id={"CL"}
                              />
                              <label htmlFor={"CL"}> Client Lead</label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {props.user <= 2 ? (
                        <div class="form__item">
                          <div class="form__value">
                            <div class="form-checkbox">
                              <input
                                type="checkbox"
                                checked={isCSC}
                                {...(roleSelected
                                  ? isCSC
                                    ? {
                                        onChange: () => {
                                          setIsCSC(!isCSC);
                                          UnselectUser();
                                          setRoleSelected(!roleSelected);
                                        },
                                      }
                                    : { readOnly: true }
                                  : {
                                      onChange: () => {
                                        setIsCSC(!isCSC);
                                        UnselectUser();
                                        setRoleSelected(!roleSelected);
                                      },
                                    })}
                                id={"CSC"}
                              />
                              <label htmlFor={"CSC"}> CSC</label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {props.user <= 3 ? (
                        <div class="form__item">
                          <div class="form__value">
                            <div class="form-checkbox">
                              <input
                                type="checkbox"
                                checked={iszCSC}
                                {...(roleSelected
                                  ? iszCSC
                                    ? {
                                        onChange: () => {
                                          setIszCSC(!iszCSC);
                                          UnselectUser();
                                          setRoleSelected(!roleSelected);
                                        },
                                      }
                                    : { readOnly: true }
                                  : {
                                      onChange: () => {
                                        setIszCSC(!iszCSC);
                                        UnselectUser();
                                        setRoleSelected(!roleSelected);
                                      },
                                    })}
                                id={"zCSC"}
                              />
                              <label htmlFor={"zCSC"}>CSC - Zastupujíci</label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                      {props.user <= 3 ? (
                        <div class="form__item">
                          <div class="form__value">
                            <div class="form-checkbox">
                              <input
                                type="checkbox"
                                checked={isNAKUP}
                                {...(roleSelected
                                  ? isNAKUP
                                    ? {
                                        onChange: () => {
                                          setIsNAKUP(!isNAKUP);
                                          UnselectUser();
                                          setRoleSelected(!roleSelected);
                                        },
                                      }
                                    : { readOnly: true }
                                  : {
                                      onChange: () => {
                                        setIsNAKUP(!isNAKUP);
                                        UnselectUser();
                                        setRoleSelected(!roleSelected);
                                      },
                                    })}
                                id={"NAKUP"}
                              />
                              <label htmlFor={"NAKUP"}> Nákupčí</label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {props.user <= 4 ? (
                        <div class="form__item">
                          <div class="form__value">
                            <div class="form-checkbox">
                              <input
                                type="checkbox"
                                checked={iszNAKUP}
                                {...(roleSelected
                                  ? iszNAKUP
                                    ? {
                                        onChange: () => {
                                          setIszNAKUP(!iszNAKUP);
                                          UnselectUser();
                                          setRoleSelected(!roleSelected);
                                        },
                                      }
                                    : { readOnly: true }
                                  : {
                                      onChange: () => {
                                        setIszNAKUP(!iszNAKUP);
                                        UnselectUser();
                                        setRoleSelected(!roleSelected);
                                      },
                                    })}
                                id={"zNAKUP"}
                              />
                              <label htmlFor={"zNAKUP"}>
                                Nákupčí - Zastupujíci
                              </label>
                            </div>
                            {validace == 2 ? (
                              <div class="validation">
                                <div class="validation__item">
                                  Vyberte alespoň jednu roli!
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </form>
                  </div>
                  <div class="form__col">
                    <form class="form__in">
                      {
                        //potrebujeme vyfiltrovat osoby, ktore su priradene k vsetkym vybranym klientom. Teda, hladame prienik.
                        // Vytvorime array s key user a value pocet kolko ma priradenych vybranych klientov.
                        //A potom vyberiem userov ktory maju rovnaky pocet klientov ako je pocet vybranych.
                        props.users
                          .filter((o) =>
                            [
                              ...props.usersClients
                                .filter((o) =>
                                  props.zakazka
                                    .filter((o) => o.SELECTED === 1)
                                    .map((o) => o.ID_CLIENTS)
                                    .includes(o.CLIENT_ID)
                                ) //vyfiltrujeme riadky, ktore maju daneho ID klienta vyplneme
                                .map((o) => o.USERS_ID) // vyberieme stlpec user
                                .reduce(
                                  (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
                                  new Map()
                                ), // count unikatnych hodnot, ktore potom porovname s poctom vybranych klientov
                            ] //zmenime Map object na array
                              .filter(
                                (o) =>
                                  o[1] ==
                                  props.zakazka
                                    .filter((o) => o.SELECTED === 1)
                                    .map((o) => o.ID_CLIENTS)
                                    .filter(
                                      (value, index, self) =>
                                        self.indexOf(value) === index
                                    ).length //pocet vybranych klientov sa musi rovnat poctu vyplnenych klientov
                              )
                              .map((o) => o[0]) //vyberieme Key, co reprezentuje ID usera
                              .includes(o.ID)
                          )
                          .map((users, index) =>
                            userSelected ? (
                              users.SELECTED == 1 ? (
                                <div class="form__item">
                                  <div class="form__value">
                                    <div class="form-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={users.SELECTED}
                                        onChange={(event) =>
                                          SelectUser(event, users.ID)
                                        }
                                        id={index}
                                      />
                                      <label htmlFor={index}>
                                        {users.FULL_NAME}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            ) : isCL ? (
                              users.ROLE <= 2 ? (
                                <div class="form__item">
                                  <div class="form__value">
                                    <div class="form-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={users.SELECTED}
                                        onChange={(event) =>
                                          SelectUser(event, users.ID)
                                        }
                                        id={index}
                                      />
                                      <label htmlFor={index}>
                                        {users.FULL_NAME}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            ) : isCSC || iszCSC ? (
                              users.ROLE <= 3 ? (
                                <div class="form__item">
                                  <div class="form__value">
                                    <div class="form-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={users.SELECTED}
                                        onChange={(event) =>
                                          SelectUser(event, users.ID)
                                        }
                                        id={index}
                                      />
                                      <label htmlFor={index}>
                                        {users.FULL_NAME}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )
                            ) : (
                              <div class="form__item">
                                <div class="form__value">
                                  <div class="form-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={users.SELECTED}
                                      onChange={(event) =>
                                        SelectUser(event, users.ID)
                                      }
                                      id={index}
                                    />
                                    <label htmlFor={index}>
                                      {users.FULL_NAME}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                      }
                      {validace == 1 ? (
                        <div class="form__item">
                          <div class="form__value">
                            <div class="validation">
                              <div class="validation__item">
                                Vyberte užívatele!
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <label for="id" class="form-label form-label--inline">
                        Administrátoři
                      </label>
                      {props.users
                        .filter((o) => o.ROLE == 1)
                        .map((users, index) => (
                          <div class="form__item">
                            <div class="form__value">
                              <div class="form-checkbox">
                                <input
                                  type="checkbox"
                                  checked={users.SELECTED}
                                  onChange={(event) =>
                                    SelectUser(event, users.ID)
                                  }
                                  id={index + "a"}
                                />
                                <label htmlFor={index + "a"}>
                                  {users.FULL_NAME}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-window__actions">
              <a
                class="btn btn--medium btn--primary"
                onClick={() => HromadnyEdit()}
              >
                Pokračovat
              </a>
              <a
                class="btn btn--medium btn--bordered"
                onClick={() => props.setModal(false)}
              >
                Storno
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
