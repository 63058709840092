import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Nav(props) {
  const [selectedLink, setSelectedlink] = useState(0);

  const handleLogout = () => {
    localStorage.clear();
    props.setUser(false);
    const url = //"http://localhost:3000/LogOut";
      //"https://mind2purchases.auth.eu-central-1.amazoncognito.com/logout?client_id=23utr2f02qkht4qu3hje08j9i5&response_type=token&redirect_uri=http://localhost:3000/";
      "https://mind2test.auth.eu-central-1.amazoncognito.com/logout?client_id=5l88s06v0p2tv35l5077emg8ct&response_type=token&redirect_uri=https://zs.mind2.agency/";

    window.location.replace(url);
  };

  let buttons;

  if (props.user) {
    buttons = (
      <ul class="menu__list">
        <li class="menu__item">
          <Link to={"/Prehled/MyOrders"} className="text-link">
            <a
              className={
                selectedLink == 0
                  ? "menu__link menu__link--active"
                  : "menu__link"
              }
              onClick={() => {
                setSelectedlink(0);
              }}
            >
              Přehled
            </a>
          </Link>
        </li>
        {(props.user === 1 || props.user === 2 || props.user === 3) && (
          <li class="menu__item">
            <Link to={"/NewOrder"} className="text-link">
              <a
                className={
                  selectedLink == 1
                    ? "menu__link menu__link--active"
                    : "menu__link"
                }
                onClick={() => {
                  setSelectedlink(1);
                }}
              >
                Nová zakázka
              </a>
            </Link>
          </li>
        )}
        {props.user === 1 && (
          <>
            <li class="menu__item">
              <Link to={"/UsersManagement"} className="text-link">
                <a
                  className={
                    selectedLink == 2
                      ? "menu__link menu__link--active"
                      : "menu__link"
                  }
                  onClick={() => {
                    setSelectedlink(2);
                  }}
                >
                  Správa uživatelů
                </a>
              </Link>
            </li>
            <li class="menu__item">
              <Link to={"/ClientsManagement"} className="text-link">
                <a
                  className={
                    selectedLink == 3
                      ? "menu__link menu__link--active"
                      : "menu__link"
                  }
                  onClick={() => {
                    setSelectedlink(3);
                  }}
                >
                  Správa klientů
                </a>
              </Link>
            </li>
          </>
        )}
        {props.userDobropis == 1 ? (
          <li class="menu__item">
            <Link to={"/NewInvoice"} className="text-link">
              <a
                className={
                  selectedLink == 4
                    ? "menu__link menu__link--active"
                    : "menu__link"
                }
                onClick={() => {
                  setSelectedlink(4);
                }}
              >
                Nová faktura
              </a>
            </Link>
          </li>
        ) : (
          <></>
        )}
        <li class="menu__item">
          <Link to={"/SafeKurzy"} className="text-link">
            <a
              className={
                selectedLink == 5
                  ? "menu__link menu__link--active"
                  : "menu__link"
              }
              onClick={() => {
                setSelectedlink(5);
              }}
            >
              Safe Kurzy
            </a>
          </Link>
        </li>
        <li class="menu__item">
          <Link to={"/"} className="text-link">
            <a onClick={handleLogout} className="menu__link">
              Odhlásit
            </a>
          </Link>
        </li>
      </ul>
    );
  } else {
    buttons = (
      <ul className="navbar-nav ml auto">
        <li className="nav-item">
          <Link to={"/login"} className="nav-link">
            Login
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/signup"} className="nav-link">
            Sign up
          </Link>
        </li>
      </ul>
    );
  }
  return (
    <nav class="menu">
      <div class="container">
        <div class="menu__in">
          {buttons}
          <div class="user-panel">
            <div class="user-panel__in">
              <div class="user-panel__value">
                {props.userName + " - " + props.userRole}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

/*
<nav className="navbar navbar-expand d-flex flex-column">
<div className="container">
  <Link className="navbar-brand" to={"/"}>
    Home
  </Link>
  <div className="collapse navbar-collapse">{buttons}</div>
</div>
</nav>
*/
