import React, { useEffect, useState } from "react";
import FinancialInsightStandard from "./Order-FI_standard";
import FinancialInsightFeeOS from "./Order-FI_fee_os";
import { useParams } from "react-router-dom";

export default function FinancialInsight(props) {
  const params = useParams();
  return (
    <>
      {(params.mediatyp != "Fee") &
      (params.mediatyp != "Other services") &
      (params.mediatyp != "Internet") &
      (params.mediatyp != "Kickback") ? (
        <FinancialInsightStandard user={props.user} />
      ) : (
        <FinancialInsightFeeOS user={props.user} />
      )}
    </>
  );
}
