export function replaceComma(x) {
  if ((x !== null) & (x !== undefined)) {
    if (x.replace) {
      var number = x.replace(",", ".");
      return parseFloat(number);
    } else {
      return x;
    }
  } else {
    return x;
  }
}

export function replaceDot(x) {
  if ((x !== null) & (x !== undefined)) {
    var string = x.toString().replace(".", ",");
    return string;
  } else {
    return x;
  }
}

export function numberWithSpaces(x) {
  if (x != null) {
    if (x.toFixed) {
      x = +x.toFixed(2); //parseFloat("123.456").toFixed(2); keby prisiel string tak na zaukrohlovanie pouzijem toto
    }

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
  } else {
    return x;
  }
}

export function checkValidInput(e, previousKey, setPreviousKey) {
  /*
  var a = event.keycode || event.which;

  if (a > 47 && a < 58) {
    //If pressed key is number then it will allow

    return true;
  } else {
    //If pressed key is not number then it will not allow to press key

    event.preventDefault();
  }

  return true;*/

  var allowedChars = e.ctrlKey ? "-0123456789,Controlv" : "-0123456789,Control";
  // var allowedChars = "0123456789,Controlv";
  function contains(stringValue, charValue) {
    return stringValue.indexOf(charValue) > -1;
  }
  var invalidKey =
    (e.key.length === 1 && !contains(allowedChars, e.key)) ||
    (e.key === "," && contains(e.target.value, ","));
  invalidKey && e.preventDefault();
}

export function CheckValidPaste(e) {
  debugger;
  var textContent = (
    e.type === "paste" ? e.clipboardData : e.dataTransfer
  ).getData("text");

  const regex = /(?!\d) +(?=\d)/g;
  // vyhodi vsetky medzery ktore su medzi cislami
  textContent = textContent.replace(regex, "");
  var newtextContent = (e.target.value + String(textContent)).replace(",", ".");
  !isNumeric(newtextContent) && e.preventDefault();
}

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function getSumByKey(arr, key) {
  return arr.reduce(
    (accumulator, current) => accumulator + Number(current[key]),
    0
  );
}

export function cutFile(x) {
  var first;

  try {
    first = /(?<=([^/]*\/){3}).*/.exec(x)[0]; //odstranime prve 3 /
    first = /(?<=([^_]*_){2}).*/.exec(first)[0]; //potom odstranime prve _
    first = first.replace(/\.[^/.]+$/, ""); // odstranem file priponu
  } catch {
    try {
      first = /(?<=([^_]*_){2}).*/.exec(x)[0];
      first = first.replace(/\.[^/.]+$/, "");
    } catch {
      first = x;
    }
  }
  return first;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

/* const HandleCalendar = (value) => {
    var x;
    x = formatDate(value);
    setCalendarValue(x);
    console.log(x);
  };*/
export function formatDateTime(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hours = "" + d.getHours(),
    minutes = "" + d.getMinutes(),
    seconds = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  var datum = [year, month, day].join("-");
  var time = [hours, minutes, seconds].join(":");
  return [datum, time].join(" ");
}

export function IsValidDate(dateString) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

function removeItemAll(arr, value, col) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i][col] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export function compareTime(time1, time2) {
  return new Date(time1) > new Date(time2); // true if time1 is later
}

export function myfunction(
  headerRef,
  leftOffset,
  setLeftOffset,
  offsetDefault,
  obj
) {
  if (headerRef.current) {
    setLeftOffset(headerRef.current.offsetTop);
  }

  const handleScroll = () => {
    if (headerRef.current) {
      headerRef.current.style.top = `${
        window.scrollY + leftOffset - offsetDefault
      }px`;
    }
  };
  var target_obj = obj;
  target_obj.addEventListener("scroll", handleScroll);

  return () => {
    target_obj.removeEventListener("scroll", handleScroll);
  };
}
