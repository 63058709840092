import React, { useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import ModalRunning from "./modal_running";

export default function MediaplanModalImport(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const toggleModal = () => {
    props.setModal(!props.modal);
    console.log(selectedFile);
  };

  const sortMV = (data) => {
    const values = data;
    values.sort((a, b) => b.ID - a.ID);
    props.setMediaplanVerzia(values);
  };

  let filename_update;
  let urlll;

  const handleSubmit = async () => {
    const data = new FormData();
    data.append("file", selectedFile, selectedFile.name);

    var today = new Date();
    var date = today.getFullYear() + today.getMonth() + today.getDate();
    var time = today.getMilliseconds();
    var dateTime = date + "_" + time;

    filename_update = dateTime + "_" + selectedFile.name;

    setIsRunning(true);
    // GET request: presigned URL
    await axios
      .get(
        "/mediaplannewversion?filename=" +
          filename_update +
          "&mediatyp=" +
          props.vybranyMediatyp
      )
      .then((res) => {
        console.log(res);
        urlll = res.data.uploadURL;
      })
      .catch((err) => {
        setIsRunning(false);
      });
    // PUT request: upload file to S3
    await fetch(urlll, {
      method: "PUT",
      body: selectedFile,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        setIsRunning(false);
      });

    const url =
      "/newmediaplanprint?filename=" +
      filename_update +
      "&kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&substitution=" +
      props.substitution +
      "&substituted=" +
      props.vybranyMediaplan +
      "&newImport=" +
      props.newImport;

    await axios
      .get(url)
      .then((res) => {
        // response je len Stav Zakazky
        props.setRefresh(!props.refresh);
        props.setModal(false);

        props.setInfoBox("Mediaplán byl úspěšne nahrán do systému.");
        props.setIsError(false);
        props.setModal(false);

        setIsRunning(false);

        if (res.data == 1) {
          props.setProcessType(res.data[0]);
          props.setProcessToInvoke(true);
          props.setProcessFrom("upload");
        }

        if (res.data == 2) {
          props.setProcessType(res.data[0]);
          props.setProcessToInvoke(true);
          props.setProcessFrom("upload");
        }
      })
      .catch((err) => {
        try {
          props.setIsError(true);
          props.setInfoBox(err.response.data);
          props.setModal(false);
          setIsRunning(false);
        } catch {
          props.setIsError(true);
          props.setInfoBox("Neočekávaná chyba! Mediaplán se nezdařilo nahrát!");
          props.setModal(false);
          setIsRunning(false);
        }
      });
  };

  return (
    <>
      {props.modal && (
        <div class="modal-window">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Probíha import mediaplánu"}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Import mediaplánu</div>
              <input
                class="form-input"
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />

              <div class="modal-window__actions">
                <a
                  className="btn btn--medium btn--primary"
                  onClick={handleSubmit}
                >
                  Nahrát soubor
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
